<template>
  <icon
    v-if="modelValue"
    class="field__icon field__icon--end"
    icon="x"
    :size="1.2"
    :theme="'gray'"
    style="cursor: pointer"
    @click="clear" />
  <!--
  TODO: i18n date formats etc
  -->
  <Datepicker
    ref="datePicker"
    v-bind="{ ...inputAttrs, class: '', ...pickerConfiguration }"
    v-model="value"
    :type="computedInputType"
    :hide-input-icon="true"
    :auto-apply="true"
    :format="format"
    :month-change-on-scroll="false"
    :clearable="false"
    :input-class-name="$props.class"
    :show-now-button="false"
    :enable-time-picker="enableTimePicker" />
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    class: {
      type: String,
      default: "",
    },
    pickerConfiguration: {
      type: Object,
      default: {},
    },
    modelValue: {},
    type: {},
    list: {}, // prevent inheritence of list="" on element
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "dd/MM/yyyy",
    },
  },
  emits: ["update:modelValue"],
  data: function () {
    return {
      isFocused: false,
      test: null,
    };
  },
  components: {
    // load datepicker async
    Datepicker: defineAsyncComponent(() => import("@vuepic/vue-datepicker")),
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;

      delete attrs.class;
      return attrs;
    },
    computedInputType() {
      return this.isFocused ? "date" : "text";
    },
  },
  created() {
    // load our styles async
    let style = import("@vuepic/vue-datepicker/dist/main.css");
  },
  methods: {
    clear() {
      this.$refs.datePicker.clearValue();
    },
  },
};
</script>

<style scoped lang="scss"></style>
